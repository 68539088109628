Number.prototype.pad = function(size) {
	//Only run with positive numbers
	var s = String(this);
	while (s.length < (size || 2)) {s = "0" + s;}
	return s;
}

var updateCountDown = function(countDownDate,offset,countdown_message) {
	var now = new Date().getTime () - offset;
	// Find the distance between now and the count down date
	var distance = countDownDate - now;
	// Time calculations for days, hours, minutes and seconds
	var days = Math.floor (distance / (1000 * 60 * 60 * 24));
	var hours = Math.floor (distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
	var minutes = Math.floor (distance % (1000 * 60 * 60) / (1000 * 60));
	var seconds = Math.floor((distance % (1000 * 60)) / 1000);
	current_message = 'DD HH MM SS'
		  .replace ('DD', days.pad(2) + 'd')
		  .replace ('HH', hours.pad(2) + 'h')
		  .replace ('MM', minutes.pad(2) + 'm')
		  .replace ('SS', seconds.pad(2) + 's')
		  .replace ('00d 00h ', '')
		  .replace ('00d ', '');
		// Display the result in the element with id="countDownMessage"
		$ ('#countDownMessage').html (
		  '<span>' +
			countdown_message.replace ('COUNTDOWN', '<span class="countdown-time">' + current_message + "</span>") +
			'</span>'
		);
		// If the count down is finished, write some text
		if (distance < 0) {
		  clearInterval (x);
		  $ ('#countDownMessage').html (
			'<span>' + 'This store is closing now' + '</span>'
		  );
		}
}

var x = null;//interval

var prepareCountDown = function(){
	end_date = DecoWorx.Spar.config.end_date;
	timeServer = DecoWorx.Spar.config.server_time;
	nowServer = new Date(timeServer).getTime();
	/**
	 * //IE 11 not support end_date + ' 23 59 59' string.
	 */
	if (end_date != null && DecoWorx.Spar.config.show_countdown == true) {

		var countDownDate = new Date (end_date);
		var nowClient = new Date().getTime ();
		offset = nowServer - nowClient;
		countdown_message = DecoWorx.Spar.config.countdown_message;
	  // Update the count down every 1 minute
	   updateCountDown(countDownDate,offset,countdown_message);
       var x = setInterval (function () {
       updateCountDown(countDownDate,offset,countdown_message);
	 },
        // Get today's date and time
        1000 //1 update per second
      );
    }
 }

 var toggleFundraiserState = function(){
	//Check if 'minimized value exists in sessionstorage and toggle state in sessionStorage accordingly
	if(sessionStorage.getItem('minimized')){
		sessionStorage.getItem('minimized') == 'true' ? sessionStorage.setItem('minimized', false) : sessionStorage.setItem('minimized', true)
	}else{
		sessionStorage.setItem('minimized', true)
	}
}

var setFundraiserState = function(){
	//Set fundraiser checkbox  according to 'minimized' value in sessionStorage
	sessionStorage.getItem('minimized') == 'false' || !sessionStorage.getItem('minimized') ? $('.fundraiser-checkbox').prop('checked', false) : $('.fundraiser-checkbox').prop('checked', true)
	//Make fundraiser container visible after setting checkbox option
	$('.fundraiser-container').addClass('visible')
}

jQuery(document).ready(function($) {

	//minimize or expand fundraiser container according to sessionStorage 'minimize' value
	setFundraiserState()

	/* SEARCH index*/
	$('.li-search a').click(function(event) {
		$(this).toggleClass('active');
		$('.search').toggleClass('active');
	});
	/* SEARCH INDEX 2 */
	$('.li-search-b').click(function(event) {
		$(this).hide();
		$(this).parents('.container').css('position','relative');
		$('form.search.b').fadeIn('0.1s');
		$('form.search.b input').focus();
	});

	/* PRODUCT PAGE FUNCTIONALITY */
	/* Zomm on image */
	$(function(){
    	$('#zoom').okzoom({
        	width: 150,
        	height: 150
    	});
	});

	if ($(window).width() < 768) {
		$('.img-responsive.product').attr('id', '')
	}

	 /* Filter Side Bar Image on click */
	$('ul.side-images li').click(function(event) {
		/* Act on the event */
		// var imagen = $(this).children('img').attr('src');
		var imagen = $(this).children('img').attr('data-large-src');
		$('.img-responsive.product').attr('src',imagen)

		// console.log( "imagen this", this )
	});
	prepareCountDown();

	//Set body padding top according to height of to p nav/header
	setTopPadding();

});



jQuery(document).on( 'spar-ready', function(event,$) {
	/* update navigation items */
	var basename = window.location.pathname
	basename = basename.substr( basename.lastIndexOf('/') + 1 )

	var navItems = document.querySelectorAll( "[class~='nav-item']")
	for( var i = 0; i < navItems.length; ++i ) {
		var item = navItems[i]
		var link = item.querySelector( "a" )

		if ( link && link.getAttribute( "href" ) === basename ) {
			link[ "href" ] = "#"
			item.className += " active"

			link.textContent += " "
			var span = document.createElement( "span" )
			span.className = "sr-only"
			span.appendChild( document.createTextNode("(current)") )
			link.appendChild( span )

			break
		}
	}

	navItems = document.querySelectorAll( 'footer ul[class~="pages"] a')
	for( var i = 0; i < navItems.length; ++i ) {
		link = navItems[i]
		if ( link.getAttribute( "href" ) === basename ) {
			link[ "href" ] = "#"
		}
	}

  jQuery('head').append('<link href="' + DecoWorx.Spar.config['favicon'] + '" rel="shortcut icon" type="image/x-icon" />');
})

//toggle fundraiser dropdown caret
$('#fundraiser-toggle, #fundraiser-gauge-toggle').change(function() {
	$('.fundraiser-container i').toggleClass('fa-caret-up')
	
	//When clicking caret to minimize or expand the fundraiser container, store this state in sessionStorage
	toggleFundraiserState()
  });

//Set body padding top according to height top header nav
$(window).on('resize', function(){
	setTopPadding();
});

//This function adds padding top to body to account for fixed positioned nav/header
var setTopPadding = function(){
	$('body').css('padding-top', (document.getElementById('main-top-header').clientHeight) + 'px')
}
